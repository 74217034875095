import BasicModal from '../_widgets/Modal/BasicModal';
import { Col, Row } from 'react-bootstrap';
import Input from '../_widgets/Input/Input';
import { maximumLengthAllowed, MAX_AMT_LIMIT_REACHED } from '../../Constants/stringConstants'
import { warn } from '../../Wrapper/toast/toast';
import ImageUploadBox from '../_widgets/ImageUploader/ImageBox';
import { isNullOrEmpty } from '../../Utils/stringUtils';
import { IMAGE_BASE_URL } from '../../Constants/urlConstants';

const AddEditModal = ({ modalShow, handleClose, dispatcherData, upsertBookings, buttonLoading, setDispatcherData }) => {

    return (
        <BasicModal
            show={modalShow}
            size="lg"
            handleClose={handleClose}
            heading={dispatcherData?.id > 0 ? "Edit Dispatcher" : "Add Dispatcher"}
            footer
            button1Text="Save"
            button1Click={upsertBookings}
            loading={buttonLoading === true}
        >
            <div className="patients_ui">
                <Row>
                    <Col xl={12}>
                        <div className="white_card text-start">
                            <Row className="mb-3">
                                <Col md={6} lg={4}>
                                    <div className="w-full h-48 flex items-center justify-center text-center border-2 border-dotted border-gray-400 rounded-lg bg-gray-100 cursor-pointer relative">

                                        {dispatcherData?.image?.length > 0 ? (
                                            <ImageUploadBox handleImageUpload={(e) => {

                                                const file = e.target.files[0];
                                                setDispatcherData((prev) => ({ ...prev, image: file, imagePreview: URL.createObjectURL(file) }));
                                            }
                                            } image={IMAGE_BASE_URL + dispatcherData.image} convertImage={false} />
                                        ) : <ImageUploadBox handleImageUpload={(e) => {

                                            const file = e.target.files[0];
                                            setDispatcherData((prev) => ({ ...prev, image: file, imagePreview: URL.createObjectURL(file) }));
                                        }
                                        } image={dispatcherData.image} convertImage={true} />}

                                    </div>
                                </Col>

                                <Col md={6} lg={4}>
                                    <Input
                                        isRequired
                                        maxLength={25}
                                        label="Business Name"
                                        placeholder="Business Name"
                                        type="text"
                                        value={dispatcherData?.business_name}
                                        onChange={(e) => setDispatcherData({ ...dispatcherData, business_name: e.target.value })}
                                    />
                                </Col>
                                <Col md={6} lg={4}>
                                    <Input
                                        isRequired
                                        maxLength={25}
                                        label="Name"
                                        placeholder="Name"
                                        type="text"
                                        value={dispatcherData?.name}
                                        onChange={(e) => setDispatcherData({ ...dispatcherData, name: e.target.value })}
                                    />
                                </Col>
                                <Col md={6} lg={4}>
                                    <Input
                                        isRequired
                                        maxLength={35}
                                        label="Email"
                                        placeholder="Enter Email"
                                        type="email"
                                        value={dispatcherData?.email}
                                        onChange={(e) => setDispatcherData({ ...dispatcherData, email: e.target.value })}
                                    />
                                </Col>
                                <Col md={6} lg={4}>
                                    <Input
                                        label="Password"
                                        maxLength={25}
                                        placeholder="Enter Password"
                                        type="password"
                                        value={dispatcherData?.password}
                                        onChange={(e) => setDispatcherData({ ...dispatcherData, password: e.target.value })}
                                    />
                                </Col>
                                <Col md={6} lg={4}>
                                    <Input
                                        isRequired


                                        placeholder="Enter Phone Number"
                                        inputType="phone"
                                        label="Phone Number"
                                        defaultCountry="IN"
                                        value={dispatcherData?.mno}
                                        onChange={(e) => {
                                            let val = e

                                            setDispatcherData({ ...dispatcherData, mno: val })
                                            if (e?.length >= 14) {
                                                warn(maximumLengthAllowed(14))
                                            }
                                        }}
                                    />
                                </Col>
                                <Col md={6} lg={4}>
                                    <Input


                                        label="Alternate Mobile Number"
                                        inputType="phone"
                                        placeholder="Enter Alternate Mobile Number"
                                        defaultCountry="IN"
                                        value={dispatcherData?.alt_mno}
                                        onChange={(e) => {
                                            let val = e
                                            setDispatcherData({ ...dispatcherData, alt_mno: val })
                                            if (val?.length > 15) {
                                                warn(maximumLengthAllowed(15))
                                            }
                                        }}
                                    />

                                </Col>
                                <Col md={6} lg={4}>
                                    <Input
                                        maxLength={50}
                                        label="Remark"
                                        placeholder="Enter Remark"
                                        type="text"
                                        value={dispatcherData?.remark}
                                        onChange={(e) => setDispatcherData({ ...dispatcherData, remark: e.target.value })}
                                    />
                                </Col>
                                <Col md={6} lg={4}>
                                    <Input

                                        isRequired
                                        label="Address"
                                        placeholder="Enter Address"
                                        type="text"
                                        value={dispatcherData?.address}
                                        onChange={(e) => setDispatcherData({ ...dispatcherData, address: e.target.value })}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </BasicModal>
    );
};

export default AddEditModal;
