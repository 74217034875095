import { warn } from "../Wrapper/toast/toast";

export const isNullOrEmpty = (data) => {
    return data === null || data === undefined || data.toString().trim().length < 1;
}

export const slice = (data, length = 10) => {
    let newData = data;

    if (data?.length > length) {
        newData = data?.slice(0, length) + '...';
    }

    return newData;
}

export const isValidIFSC = (inputValue) => {
    return /^[A-Za-z]{4}[0][A-Za-z0-9]{6}$/.test(inputValue);
};

export const isValidName = (data, length = 3) => {
    return !isNullOrEmpty(data) && data.toString().length >= length;
}

export const isEqualLength = (data, length) => {
    return !isNullOrEmpty(data) && data.toString().length === length;
}

export const isLengthInBetween = (data, length1, length2) => {
    return !isNullOrEmpty(data) && (data.toString().length > length1 && data.toString().length <= length2) ? true : false;
}

export function capitalizeFirstLetter(string) {
    if (string) {
        return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
    }
};
export function capitalizeWords(str) {
    return str
        .split(' ') // Split the string into an array of words
        .map(word =>
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize the first letter and lowercase the rest
        )
        .join(' '); // Join the words back together with spaces
}


export const extractLatLng = (input) => {
    if (!input || typeof input !== 'string') {
        warn('Input must be a non-empty string');
    }

    const [latitude, longitude] = input?.split(',').map(coord => coord?.trim());

    if (isNaN(latitude) || isNaN(longitude)) {
        warn('Invalid latitude or longitude value');
    }

    return { latitude: parseFloat(latitude), longitude: parseFloat(longitude) };
};
